// src/context/SocketContext.js
import React, { useMemo, useEffect, createContext, useContext, useRef, useState } from 'react';
import io from 'socket.io-client';

const SocketContext = createContext(null);

export const useSocket = () => {
    const context = useContext(SocketContext);
    if (!context) {
        console.error('useSocket must be used within a SocketProvider');
        return null;
    }
    return context;
};

export const SocketProvider = ({ children }) => {
    const socketRef = useRef(null); // Store socket persistently
    const connectionPromiseRef = useRef(null); // Store connection promise
    const [isConnected, setIsConnected] = useState(false); // Track connection status

    // Initialize the socket connection and manage its lifecycle
    const initializeSocket = () => {
        const newSocket = io.connect('https://api.formcopilot.app/dynamic_table', {
            withCredentials: true,
            transports: ['websocket'],
            maxHttpBufferSize: 1e8,
            pingTimeout: 600000,  // 10 minute
            pingInterval: 5000,  // 5 seconds
        });

        connectionPromiseRef.current = new Promise((resolve) => {
            newSocket.on('connect', () => {
                console.log('SocketProvider: Connected to WebSocket.');
                setIsConnected(true);
                resolve(newSocket); // Resolve when connected
            });

            newSocket.on('disconnect', () => {
                console.log('SocketProvider: Disconnected from WebSocket.');
                setIsConnected(false);
            });

            newSocket.on('error', (error) => {
                console.error('SocketProvider: WebSocket error:', error);
            });
        });

        socketRef.current = newSocket;
        return newSocket;
    };

    // useEffect to initialize the socket connection and cleanup on unmount. 
    // We can not use useMemo because the socket is not a pure function of props or state.
    useEffect(() => {
        console.log('SocketProvider: Initializing socket connection.');
        const socket = initializeSocket(); // Initialize the socket once

        return () => {
            console.log('SocketProvider: Cleaning up - disconnecting socket.');
            socket?.disconnect(); // Ensure cleanup on unmount
            socketRef.current = null;
            connectionPromiseRef.current = null;
            setIsConnected(false);
        };
    }, []);

    // Function to emit events only after the socket is ready
    const emitEvent = (event, data) => {
        socket.emit(event, data); // Emit event after connection is ready
    };

    return (
        <SocketContext.Provider value={{ socket: socketRef.current, emitEvent, isConnected }}>
            {children}
        </SocketContext.Provider>
    );
};
