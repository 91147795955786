import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';

const getIPAddress = async () => {
    try {
        const response = await fetch('https://api.ipify.org');
        const data = await response.text();
        return(data);
    }
    catch(error){
        console.error("IP FETCH FAILIURE:", error);
    }
}

const userIP = getIPAddress();

const Auth = ({ onLogin }) => {
    const { darkMode, toggleTheme } = useTheme();
    const navigate = useNavigate();

    const themeStyles = {
        backgroundColor: darkMode ? '#101010' : '#FFFFFF',
        color: darkMode ? 'white' : 'black',
        borderColor: darkMode ? '#1e1e1e' : '#CCCCCC',
        modalBg: darkMode ? '#121212' : '#FFFFFF',
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://api.formcopilot.app/login');
            const data = await response.json();
            if (data.login_url) {
                window.location.href = data.login_url;
            }
        } catch (error) {
            console.error('Error during login:', error);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: themeStyles.backgroundColor }}>
            <button
                onClick={toggleTheme}
                style={{
                    position: 'fixed',
                    top: '10px',
                    right: '10px',
                    padding: '10px 20px',
                    backgroundColor: '#6A55FE',
                    color: 'white',
                    border: 'none',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    zIndex: 1000
                }}
            >
                Light Switch
            </button>
            <form onSubmit={handleLogin} style={{ display: 'flex', flexDirection: 'column', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', backgroundColor: themeStyles.modalBg, width: '400px', height: '429px', alignItems: 'center', justifyContent: 'center', color: themeStyles.color }}>
                <h2 style={{ textAlign: 'center', fontWeight: 'bold', color: themeStyles.color }}>Login</h2>
                <button type="submit" style={{
                    padding: '14px',
                    backgroundColor: '#6A55FE',
                    color: 'white',
                    border: 'none',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    width: '87%',
                    margin: '18px 0',
                    fontSize: '18px',
                    fontWeight: 'bold'
                }}>
                    Login with Microsoft
                </button>
            </form>
        </div>
    );
};

export default Auth;
