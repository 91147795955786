import React, { useState, useEffect } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { highlightPlugin } from '@react-pdf-viewer/highlight';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { useTheme } from '../context/ThemeContext';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import io from 'socket.io-client';
import Modal from 'react-modal';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

Modal.setAppElement('#root');

const CustomPDFViewerTables = () => {
    const location = useLocation();
    const { pdfBase64: initialPdfUrl, jsonData: initialJsonData, formId: initialFormId } = location.state || {};
    const navigate = useNavigate();

    const [pdfUrl, setPdfUrl] = useState();
    const [pdfBase64, setPdfBase64] = useState(initialPdfUrl || null);
    const [jsonData, setJsonData] = useState({});
    const [formId, setFormId] = useState(initialFormId || null);
    const [highlightedAreas, setHighlightedAreas] = useState([]);
    const [temporaryHighlights, setTemporaryHighlights] = useState([]);
    const [isHighlightVisible, setIsHighlightVisible] = useState(true);
    const [showAllHighlights, setShowAllHighlights] = useState(true);
    const { darkMode, toggleTheme } = useTheme();
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState('');
    const [isApiLoading, setIsApiLoading] = useState(false);
    const [socket, setSocket] = useState(null);
    const [updateSocket, setUpdateSocket] = useState(null);
    const [filterEnabled, setFilterEnabled] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [leadsSelection, setLeadsSelection] = useState('two');
    const [businessAddressError, setBusinessAddressError] = useState(false);
    const [isBusinessAddress, setIsBusinessAddress] = useState(false);
    const themeStyles = {
        backgroundColor: darkMode ? '#121212' : '#FFFFFF',
        color: darkMode ? 'white' : 'black',
        borderColor: darkMode ? '#1e1e1e' : '#CCCCCC',
        buttonBackgroundColor: '#6A55FE'
    };

    const zoomPluginInstance = zoomPlugin();
    const highlightPluginInstance = highlightPlugin({
        renderHighlights: (props) => {
            return (
                <div>
                    {showAllHighlights && highlightedAreas.map((area, idx) => (
                        area.pageIndex === props.pageIndex && (
                            <div
                                key={idx}
                                style={{
                                    background: '#2183F6',
                                    opacity: 0.5,
                                    position: 'absolute',
                                    top: `${area.top * 100}%`,
                                    left: `${area.left * 100}%`,
                                    height: `${area.height * 100}%`,
                                    width: `${area.width * 100}%`,
                                    pointerEvents: 'none',
                                    transform: `rotate(${props.rotation}deg)`,
                                    transition: 'opacity 2s'
                                }}
                            />
                        )
                    ))}
                    {temporaryHighlights.map((area, idx) => (
                        area.pageIndex === props.pageIndex && (
                            <div
                                key={idx}
                                style={{
                                    background: '#2183F6',
                                    opacity: isHighlightVisible ? 0.5 : 0,
                                    position: 'absolute',
                                    top: `${area.top * 100}%`,
                                    left: `${area.left * 100}%`,
                                    height: `${area.height * 100}%`,
                                    width: `${area.width * 100}%`,
                                    pointerEvents: 'none',
                                    transform: `rotate(${props.rotation}deg)`,
                                    transition: 'opacity 2s'
                                }}
                            />
                        )
                    ))}
                </div>
            );
        },
    });

    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { jumpToPage } = pageNavigationPluginInstance;
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

    const fetchDataFromWebSocket = (socket) => {
        if (formId) {
            console.log('[CustomPDFViewerTables] Emitting get_nor_cal_template_table');
            socket.emit('get_nor_cal_template_table', { formid: formId });
        }
    };

    useEffect(() => {
        console.log('[CustomPDFViewerTables] Checking pdfBase64 state:', pdfBase64);
        if (pdfBase64) {
            try {
                console.log('[CustomPDFViewerTables] pdfBase64 exists:', pdfBase64.slice(0, 30));
                const byteCharacters = atob(pdfBase64);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });

                const objectUrl = URL.createObjectURL(blob);
                console.log('[CustomPDFViewerTables] objectUrl:', objectUrl);
                setPdfUrl(objectUrl);

                return () => URL.revokeObjectURL(objectUrl);
            } catch (error) {
                console.error('[CustomPDFViewerTables] Error converting base64 to Blob:', error);
            }
        } else {
            console.log('[CustomPDFViewerTables] pdfBase64 is empty or null');
        }
    }, [pdfBase64]);

    useEffect(() => {
        if (initialPdfUrl && initialJsonData) {
            console.log('[CustomPDFViewerTables] Received initial JSON data:', initialJsonData);
            extractAndHighlightFromJson(initialJsonData.documents[0].fields);
        }
    }, [initialPdfUrl, initialJsonData]);

    useEffect(() => {
        const newSocket = io.connect('https://api.formcopilot.app/dynamic_table', {
            withCredentials: true, // Ensures cookies (including JWT and CSRF tokens) are sent automatically
            transports: ['websocket']
        });

        setSocket(newSocket);

        newSocket.on('connect', () => {
            console.log('[CustomPDFViewerTables] Connected to WebSocket');
            fetchDataFromWebSocket(newSocket);
        });

        newSocket.on('json_data', (data) => {
            console.log('[CustomPDFViewerTables] Received JSON data:', data);
            setJsonData(data);
        });

        newSocket.on('processed_pdfs', (data) => {
            console.log('[CustomPDFViewerTables] Received processed PDFs:', data);
            data.forEach(file => downloadFile(file.file_name, file.base64pdf));
        });

        newSocket.on('minutes_generated', (data) => {
            console.log('[CustomPDFViewerTables] Minutes generated:', data);
            downloadFile(data.file_name, data.base64docx);
        });

        newSocket.on('error', (error) => {
            console.error('[CustomPDFViewerTables] WebSocket error:', error);
        });

        newSocket.on('disconnect', () => {
            console.log('[CustomPDFViewerTables] Disconnected from WebSocket');
        });

        const newUpdateSocket = io('https://api.formcopilot.app/modify_table', {
            withCredentials: true, // Ensures cookies (including JWT and CSRF tokens) are sent automatically
            transports: ['websocket']
        });
        setUpdateSocket(newUpdateSocket);

        return () => {
            newSocket.disconnect();
            newUpdateSocket.disconnect();
        };
    }, [formId]);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setPdfUrl(fileUrl);
            setFileName(file.name);
        }
        if (!file) return;
        setLoading(true);
        const fileUrl = URL.createObjectURL(file);
        setPdfUrl(fileUrl);

        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result;
            const base64Content = base64String.split(',')[1];
            const isDivisibleBy4 = base64Content.length % 4 === 0;
            let paddedBase64Content = base64Content;
            if (!isDivisibleBy4) {
                const missingPadding = 4 - (base64Content.length % 4);
                paddedBase64Content += '='.repeat(missingPadding);
            }
            const base64StringWithPadding = `${base64String.split(',')[0]},${paddedBase64Content}`;

            console.log('[CustomPDFViewerTables] Sending PDF to server');
            axios({
                method: 'post',
                url: 'https://api.formcopilot.app/form_copilot',
                data: {
                    body: base64StringWithPadding,
                    formId: formId,
                    documentType: 'NorCal-Template-1',
                },
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            })
                .then(response => {
                    console.log('[CustomPDFViewerTables] PDF sent successfully');
                    setLoading(false);
                    fetchDataFromWebSocket(socket);
                })
                .catch(error => {
                    console.error('[CustomPDFViewerTables] Error posting PDF:', error);
                    setJsonData({ error: error.toString() });
                    setLoading(false);
                });
        };

        reader.onerror = error => {
            console.error('[CustomPDFViewerTables] Error reading file:', error);
            setJsonData({ error: error.toString() });
        };
        reader.readAsDataURL(file);
    };

    const extractAndHighlightFromJson = (jsonData) => {
        const highlightedAreas = [];

        Object.entries(jsonData).forEach(([key, field]) => {
            if (field.boundingRegions) {
                field.boundingRegions.forEach(region => {
                    const polygon = region.polygon;
                    const [x1, y1, x2, y2, x3, y3, x4, y4] = polygon;
                    const left = Math.min(x1, x2, x3, x4);
                    const top = Math.min(y1, y2, y3, y4);
                    const width = Math.max(x1, x2, x3, x4) - left;
                    const height = Math.max(y1, y2, y3, y4) - top;

                    highlightedAreas.push({
                        top: top / 11,
                        left: left / 8.5,
                        height: height / 11,
                        width: width / 8.5,
                        pageIndex: region.pageNumber - 1,
                        fieldKey: key,
                    });
                });
            }
        });

        setHighlightedAreas(highlightedAreas);
    };

    const handlePercentages = (x) => {
        return Math.round(x);
    };

    const handlePanelClick = (boundingRegions) => {
        setShowAllHighlights(false);
        setHighlightedAreas([]);
        if (boundingRegions && boundingRegions.length > 0) {
            const region = boundingRegions[0];
            const pageIndex = region.pageNumber - 1;
            jumpToPage(pageIndex);

            const [x1, y1, x2, y2, x3, y3, x4, y4] = region.polygon;
            const left = Math.min(x1, x2, x3, x4);
            const top = Math.min(y1, y2, y3, y4);
            const width = Math.max(x1, x2, x3, x4) - left;
            const height = Math.max(y1, y2, y3, y4) - top;

            const highlightedArea = {
                top: top / 11,
                left: left / 8.5,
                height: height / 11,
                width: width / 8.5,
                pageIndex: pageIndex,
            };

            setTemporaryHighlights([highlightedArea]);
            setIsHighlightVisible(true);
            setTimeout(() => {
                setIsHighlightVisible(false);
                setTimeout(() => {
                    setTemporaryHighlights([]);
                }, 2000);
            }, 1000);
        }
    };

    const toggleShowAllHighlights = () => {
        setShowAllHighlights(!showAllHighlights);
    };

    useEffect(() => {
        if (showAllHighlights) {
            setTemporaryHighlights([]);
            setIsHighlightVisible(false);
        }
    }, [showAllHighlights]);

    const handleInputChange = (key, newValue) => {
        const valueToSet = typeof newValue === 'object' ? JSON.stringify(newValue) : newValue;

        setJsonData(prevJsonData => {
            const updatedJsonData = {
                ...prevJsonData,
                [key]: {
                    ...prevJsonData[key],
                    valueString: valueToSet
                }
            };
            if (updateSocket) {
                console.log('[CustomPDFViewerTables] Emitting modify_item event:', { formid: formId, key, value: valueToSet });
                updateSocket.emit('modify_item', {
                    formid: formId,
                    key: key,
                    value: valueToSet
                });
            }
            return updatedJsonData;
        });
    };

    const handleGeneratePDFs = () => {
        if (socket && formId) {
            console.log('[CustomPDFViewerTables] Emitting generate_workflow_pdfs event');
            socket.emit('generate_workflow_pdfs', { form_id: formId });
        }
    };

    const handleGenerateInvoices = () => {
        setIsModalOpen(true);
    };

    const handleDownloadInvoices = () => {
        if (socket && formId) {
            console.log('[CustomPDFViewerTables] Emitting generate_nor_cal_invoice event');
            socket.emit('generate_nor_cal_invoice', { form_id: formId, two_or_three_people: leadsSelection });
        }
        console.log('[CustomPDFViewerTables] generate_nor_cal_invoice');
        setIsModalOpen(false);
    };

    const handleGenerateMinutes = () => {
        if (socket && formId) {
            console.log('[CustomPDFViewerTables] Emitting generate_250k_less_minutes event through WebSocket');
            socket.emit('generate_250k_less_minutes', { form_id: formId });
            console.log('[CustomPDFViewerTables] Emitting generate_250k_less_minutes event through WebSocket success')
        } else {
            console.error('[CustomPDFViewerTables] Socket is not connected or formId is missing.');
        }
    };

    const downloadFile = (fileName, base64Content) => {
        const extension = fileName.split('.').pop();
        let mimeType;

        switch (extension) {
            case 'pdf':
                mimeType = 'application/pdf';
                break;
            case 'xlsx':
                mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                break;
            case 'docx':
                mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                break;
            default:
                mimeType = 'application/octet-stream';
        }

        const linkSource = `data:${mimeType};base64,${base64Content}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const toggleFilter = () => {
        setFilterEnabled(!filterEnabled);
    };

    const renderKeyLabel = (key) => {
        return key.replace(/_/g, ' ');
    };

    const dateFields = ['Date_For_Forms', 'First_Payment_Date', 'Maturity_Date', 'Loan_Guarantee_Page_4_Maturity_Date', 'Loan_Document_Date'];
    const yesNoFields = ['Climate_Tech', 'Risk_Insurance', 'Fire_Insurance', 'Broad_Form_Security_UCC', 'Collateral_Security', 'Deed_Of_Trust', 'Appraisal'];
    const handleBusinessAddressChange = (value) => {
        setIsBusinessAddress(true);
        const commaCount = (value.match(/,/g) || []).length;
        if (commaCount !== 2 && value.trim() !== "") {
            setBusinessAddressError(true);
        } else {
            setBusinessAddressError(false);
        }
    };


    const renderInputField = (key, value) => {
        const isBusinessAddress = key.toLowerCase() === 'business_address';

        return (
            <div style={{ position: 'relative' }}>
                <textarea
                    value={typeof value === 'object' ? value.valueString : value}
                    onChange={(e) => {
                        handleInputChange(key, e.target.value);
                        if (isBusinessAddress) handleBusinessAddressChange(e.target.value);
                    }}
                    style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        width: '100%',
                        padding: '5px',
                        border: businessAddressError && isBusinessAddress ? '1px solid red' : '1px solid #ccc',
                        borderRadius: '4px',
                        boxSizing: 'border-box',
                        resize: 'vertical'
                    }}
                    rows={1}

                    className='text-[#363C45] focus:outline-none focus:border-none active:border-none'
                />

                {isBusinessAddress && businessAddressError && (
                    <p style={{ color: 'red', marginTop: '5px' }}>Requires two commas</p>
                )}
            </div>
        );
    };


    return (
        <div style={{ display: 'flex', height: '100vh', backgroundColor: '#f1f1f1', overflow: 'hidden' }}>
            <div style={{ width: '60%', padding: '2px 20px', position: 'relative' }}>
                <div style={{ padding: '10px 0px', display: 'flex', alignItems: 'center', gap: '5px', justifyContent:'space-between' }}>
                    <button className='w-[44px] h-[44px] bg-white flex items-center justify-center rounded-[4px]'
                        onClick={() => {
                            navigate('/dynamic-table')
                        }}
                    >
                        <img src="/images/ArrowLeft.svg" width={24} height={24} alt="Go Back" />
                    </button>
                    <div className='flex items-center gap-1'>
                        <label htmlFor="file-upload" className='cursor-pointer bg-white text-[#2183F6] font-[500] px-[20px] py-[10px] rounded-[4px] mr-1 flex items-center gap-1'>Upload Document <img src="/images/FileArrowUp.svg" width={16} height={16} alt="UploadFile" /></label>
                        {/* <span style={{ flexGrow: 1, backgroundColor: '#f0f0f0', padding: '8px', borderRadius: '8px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {fileName || 'No file chosen'}
                    </span> */}
                        <input
                            id="file-upload"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            accept="application/pdf"
                            required
                        />
                        <button onClick={handleGeneratePDFs}
                            className='cursor-pointer bg-white text-[#2183F6] font-[500] px-[20px] py-[10px] rounded-[4px]'>
                            Generate Ibank PDFs
                        </button>
                        <button onClick={handleGenerateInvoices}
                            className='cursor-pointer bg-white text-[#2183F6] font-[500] px-[20px] py-[10px] rounded-[4px]'>
                            Generate Commissions
                        </button>
                        <button onClick={handleGenerateMinutes}
                            className='cursor-pointer bg-white text-[#2183F6] font-[500] px-[20px] py-[10px] rounded-[4px]'>
                            Generate Minutes
                        </button>
                        <button
                            className='cursor-pointer bg-white text-[#2183F6] font-[500] px-[20px] py-[10px] rounded-[4px]'
                            onClick={toggleFilter}
                        >
                            Filter
                        </button>
                        <button className='w-[44px] h-[44px] bg-white flex items-center justify-center rounded-[4px]'
                            onClick={() => {
                                navigate('/dynamic-table')
                            }}>
                            <img src="/images/HouseSimple.svg" width={24} height={24} alt="Go Back" />
                        </button>
                    </div>
                </div>
                {pdfUrl && (
                    <div style={{ position: 'relative', width: '100%', height: '100vh', overflow: 'hidden' }}>
                        <Worker workerUrl={pdfjsWorker}>
                            <div style={{ height: '100%', overflow: 'hidden' }}>
                                <Viewer
                                    fileUrl={pdfUrl}
                                    plugins={[zoomPluginInstance, highlightPluginInstance, pageNavigationPluginInstance]}
                                    onDocumentLoad={() => console.log('Document loaded')}
                                    defaultScale={.95}
                                />
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', backgroundColor: 'white' }}>
                                    <ZoomOutButton />
                                    <ZoomPopover />
                                    <ZoomInButton />
                                </div>
                            </div>
                        </Worker>
                    </div>
                )}
            </div>
            <div style={{ width: '40%', marginTop: '10px', overflow: 'hidden' }}>
                <div style={{ width: '100%', overflowY: 'auto', height: 'calc(100vh - 32px)', padding: '0px 20px' }} className='Details'>
                    {jsonData ? (
                        <>
                            {Object.entries(jsonData).filter(([key]) => {
                                if (!filterEnabled) return true;
                                const filterKeys = ['Date_For_Forms', 'business_name', 'Business_Address', "Loan_Officer_Name", "Loan_Officer_Title", "Bank_Email", "Bank_Name", "Bank_Address", "FDC_Name", "FDC_Contact_Name", "FDC_Contact_Title", "FDC_Contact_Email", "FDC_Address", "Guarantee_Type", "Loan_Amount", "Loan_Type", "Guarantee_Fee_Percent", "Guarantee_Amount", "Guarantee_Expiration", "Term_Months", "Est_Payment", "First_Payment_Date", "Maturity_Date", "Loan_Guarantee_Page_4_Maturity_Date", "Interest_Rate", "Alternate_Payment_Plan", "Purpose_Of_Loan", 'IBANK_Loan_#', "Climate_Tech", "Loan_Doc_Fee", "Risk_Insurance", "Business_Property_Address", "Fire_Insurance", "Broad_Form_Security_UCC", "Collateral_Security", "Deed_Of_Trust", "Annual_Tax_Returns", "Annual_Return_Provided_Days_After_Filing", "Quarterly_Business_Financials", "Quarterly_Business_Returns_Days_After_Filing", "Personal_Finance_Statement", "Personal_Returns_Days_After_Filing", "Appraisal", "Appraisal_Minimum_Value_Number", "Title_Report_Type", "Total_Fee", "Lien_Position", "Collateral_Property_Owner", "Collateral_Property_Address", "Additional_Collateral", "Limitations", "Additional_Reporting_Requirements", "FDC_Representative"];
                                const filterKeysLowerCase = filterKeys.map(key => key.toLowerCase());
                                return filterKeysLowerCase.includes(key.toLowerCase());
                            }).map(([key, value], index) => (
                                key !== '_id' && (
                                    <div
                                        key={index}
                                        onClick={() => handlePanelClick(value.boundingRegions)}
                                        style={{ padding: "10px", backgroundColor: 'white', color: 'black', cursor: 'pointer', position: 'relative', width: '100%', margin: '2px 0px', borderRadius: '4px', overflow: 'hidden' }}
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                            <h4 style={{ textTransform: 'capitalize', margin: '0', color: '#535C69', flex: '1 1 auto', maxWidth: '70%' }}>{renderKeyLabel(key)}</h4>
                                            {value.confidence !== undefined && (
                                                <p style={{ margin: '0', flex: '0 1 auto' }}>{handlePercentages(value.confidence * 100)}%</p>
                                            )}
                                        </div>
                                        {renderInputField(key, value)}
                                    </div>
                                )
                            ))}
                        </>
                    ) :
                        <>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {loading ? <i className="fas fa-spinner fa-spin fa-3x"></i> : <h2>Please Upload Document</h2>}
                            </div>
                        </>}
                </div>
            </div>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="Select Leads"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '300px',
                        padding: '20px',
                        textAlign: 'center'
                    }
                }}
            >
                <h2>Select Leads</h2>
                <div>
                    <label>
                        <input
                            type="radio"
                            value="two"
                            checked={leadsSelection === 'two'}
                            onChange={(e) => setLeadsSelection(e.target.value)}
                        />
                        Two Leads
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type="radio"
                            value="three"
                            checked={leadsSelection === 'three'}
                            onChange={(e) => setLeadsSelection(e.target.value)}
                        />
                        Three Leads
                    </label>
                </div>
                <button onClick={handleDownloadInvoices} style={{ marginTop: '20px', padding: '10px 20px', backgroundColor: themeStyles.buttonBackgroundColor, color: 'white', border: 'none', borderRadius: '8px', cursor: 'pointer' }}>
                    Download Invoices
                </button>
            </Modal>
        </div>
    );
};

export default CustomPDFViewerTables;
