import React, { useState, useEffect, useRef } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { highlightPlugin } from '@react-pdf-viewer/highlight';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { useTheme } from '../context/ThemeContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSocket } from '../context/SocketContext';
import axios from 'axios';


pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PDFViewertester = () => {
    const location = useLocation();
    const { pdfBase64: initialPdfUrl, jsonData: initialJsonData, iframe: initialIframeData, formId: initialFormId } = location.state || {};
    const navigate = useNavigate();

    const [pdfUrl, setPdfUrl] = useState();
    const [pdfBase64, setPdfBase64] = useState(initialPdfUrl || null);
    const [jsonData, setJsonData] = useState([]);
    const [formId, setFormId] = useState(initialFormId || null);
    const [googleDocsUrl, setGoogleDocsUrl] = useState(initialIframeData || "https://docs.google.com/document/d/1YXu_Uu6Z5hdjqjRUX71PU1D_KPiKWe1Q/edit?usp=sharing&ouid=103844593516674711347&rtpof=true&sd=true");
    const [highlightedAreas, setHighlightedAreas] = useState([]);
    const [temporaryHighlights, setTemporaryHighlights] = useState([]);
    const [isHighlightVisible, setIsHighlightVisible] = useState(true);
    const [showAllHighlights, setShowAllHighlights] = useState(true);
    const { darkMode, toggleTheme } = useTheme();
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState('');
    const [isApiLoading, setIsApiLoading] = useState(false);
    const { socket, isConnected } = useSocket();

    const themeStyles = {
        backgroundColor: darkMode ? '#121212' : '#FFFFFF',
        color: darkMode ? 'white' : 'black',
        borderColor: darkMode ? '#1e1e1e' : '#CCCCCC',
        buttonBackgroundColor: '#6A55FE'
    };

    const zoomPluginInstance = zoomPlugin();
    const highlightPluginInstance = highlightPlugin({
        renderHighlights: (props) => {
            return (
                <div>
                    {showAllHighlights && highlightedAreas.map((area, idx) => (
                        area.pageIndex === props.pageIndex && (
                            <div
                                key={idx}
                                style={{
                                    background: '#2183F6',
                                    opacity: 0.5,
                                    position: 'absolute',
                                    top: `${area.top * 100}%`,
                                    left: `${area.left * 100}%`,
                                    height: `${area.height * 100}%`,
                                    width: `${area.width * 100}%`,
                                    pointerEvents: 'none',
                                    transform: `rotate(${props.rotation}deg)`,
                                    transition: 'opacity 2s'
                                }}
                            />
                        )
                    ))}
                    {temporaryHighlights.map((area, idx) => (
                        area.pageIndex === props.pageIndex && (
                            <div
                                key={idx}
                                style={{
                                    background: '#2183F6',
                                    opacity: isHighlightVisible ? 0.5 : 0,
                                    position: 'absolute',
                                    top: `${area.top * 100}%`,
                                    left: `${area.left * 100}%`,
                                    height: `${area.height * 100}%`,
                                    width: `${area.width * 100}%`,
                                    pointerEvents: 'none',
                                    transform: `rotate(${props.rotation}deg)`,
                                    transition: 'opacity 2s'
                                }}
                            />
                        )
                    ))}
                </div>
            );
        },
    });

    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { jumpToPage } = pageNavigationPluginInstance;

    useEffect(() => {
        if (pdfBase64) {
            try {
                const byteCharacters = atob(pdfBase64);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });

                // Create object URL from Blob
                const objectUrl = URL.createObjectURL(blob);
                setPdfUrl(objectUrl);

                // Clean up the object URL when the component unmounts
                return () => URL.revokeObjectURL(objectUrl);
            } catch (error) {
                console.error('Error converting base64 to Blob:', error);
            }
        }
    }, [pdfBase64]);

    useEffect(() => {
        if (initialPdfUrl && initialJsonData) {
            setJsonData(initialJsonData.documents[0].fields);
            extractAndHighlightFromJson(initialJsonData.documents[0].fields);
        }
    }, [initialPdfUrl, initialJsonData]);

    useEffect(() => {
        socket.on('form_copilot_response', (response) => {
            console.log('[custom pdf viewer] form copilot response');
            if (response && response.documents && response.documents[0] && response.documents[0].fields) {
                setJsonData(response.documents[0].fields);
                extractAndHighlightFromJson(response.documents[0].fields);
                setLoading(false);
            } else {
                console.error('Invalid response structure:', response);
                setJsonData({ error: 'Invalid response structure' });
                setLoading(false);
            }
        });

        // Listen for 'error' event
        socket.on('error', (error) => {
            console.error('Socket error:', error.msg);
            setJsonData({ error: error.msg });
            setLoading(false);
        });

        // Clean up the event listeners on unmount
        return () => {
            socket.off('form_copilot_response');
            socket.off('error');
        };
    }, []);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setPdfUrl(fileUrl);
            setFileName(file.name);
        }
        if (!file) return;
        setLoading(true);
        const fileUrl = URL.createObjectURL(file);
        setPdfUrl(fileUrl);

        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result;
            const base64Content = base64String.split(',')[1];
            const isDivisibleBy4 = base64Content.length % 4 === 0;
            let paddedBase64Content = base64Content;
            if (!isDivisibleBy4) {
                const missingPadding = 4 - (base64Content.length % 4);
                paddedBase64Content += '='.repeat(missingPadding);
            }
            const base64StringWithPadding = `${base64String.split(',')[0]},${paddedBase64Content}`;
            console.log('emitting form_copilot');
            if (!socket){
                console.log('socket not working');
            }
            socket.emit('form_copilot', {
                data: {
                    body: base64StringWithPadding,
                    formId: formId,
                },
            });
        };

        reader.onerror = error => {
            console.error('Error reading file:', error);
            setJsonData({ error: error.toString() });
        };
        reader.readAsDataURL(file);
    };

    const extractAndHighlightFromJson = (jsonData) => {
        const highlightedAreas = [];

        Object.entries(jsonData).forEach(([key, field]) => {
            if (field.boundingRegions) {
                field.boundingRegions.forEach(region => {
                    const polygon = region.polygon;
                    const [x1, y1, x2, y2, x3, y3, x4, y4] = polygon;
                    const left = Math.min(x1, x2, x3, x4);
                    const top = Math.min(y1, y2, y3, y4);
                    const width = Math.max(x1, x2, x3, x4) - left;
                    const height = Math.max(y1, y2, y3, y4) - top;

                    highlightedAreas.push({
                        top: top / 11,
                        left: left / 8.5,
                        height: height / 11,
                        width: width / 8.5,
                        pageIndex: region.pageNumber - 1,
                        fieldKey: key,
                    });
                });
            }
        });

        setHighlightedAreas(highlightedAreas);
    };

    const handlePercentages = (x) => {
        return Math.round(x);
    };

    const handlePanelClick = (boundingRegions) => {
        setShowAllHighlights(false);
        setHighlightedAreas([]);
        if (boundingRegions && boundingRegions.length > 0) {
            const region = boundingRegions[0];
            const pageIndex = region.pageNumber - 1;
            jumpToPage(pageIndex);

            const [x1, y1, x2, y2, x3, y3, x4, y4] = region.polygon;
            const left = Math.min(x1, x2, x3, x4);
            const top = Math.min(y1, y2, y3, y4);
            const width = Math.max(x1, x2, x3, x4) - left;
            const height = Math.max(y1, y2, y3, y4) - top;

            const highlightedArea = {
                top: top / 11,
                left: left / 8.5,
                height: height / 11,
                width: width / 8.5,
                pageIndex: pageIndex,
            };

            setTemporaryHighlights([highlightedArea]);
            setIsHighlightVisible(true);
            setTimeout(() => {
                setIsHighlightVisible(false);
                setTimeout(() => {
                    setTemporaryHighlights([]);
                }, 2000);
            }, 1000);
        }
    };

    const toggleShowAllHighlights = () => {
        setShowAllHighlights(!showAllHighlights);
    };

    useEffect(() => {
        if (showAllHighlights) {
            setTemporaryHighlights([]);
            setIsHighlightVisible(false);
        }
    }, [showAllHighlights]);

    const handleApiCall = () => {
        console.log('updating google docs');
        setIsApiLoading(true);
        const dataToSend = Object.entries(jsonData).map(([key, field]) => ({
            title: key,
            content: field.valueString
        }));

        axios({
            method: 'post',
            url: 'https://api.formcopilot.app/generate_google_docs',
            data: {
                formId: formId,
                dataToSend,
            },
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true // Ensure cookies are sent with the request
        })
            .then(response => {
                if (response.data && response.data.shareableLink) {
                    setGoogleDocsUrl(response.data.shareableLink);
                }
                setIsApiLoading(false);
            })
            .catch(error => {
                console.error('Error making API call:', error);
                setIsApiLoading(false);
            });
    };

    // New function to handle PDF download
    const handleDownloadPdf = () => {
        if (pdfUrl) {
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = fileName || 'Credit Memo.pdf';
            link.click();
        }
    };

    return (
        <div style={{ display: 'flex', height: '100vh', backgroundColor: '#f1f1f1', overflow: 'hidden' }}>
            <iframe
                src={googleDocsUrl}
                style={{ width: '46%', height: '100%', border: 'none' }}
                title="Google Document"
            ></iframe>
            <div style={{ padding: '5px 20px', position: 'relative' }}>
                <div className='flex justify-between items-center'>
                    <div>
                        <button className='w-[44px] h-[44px] bg-white flex items-center justify-center rounded-[4px]'
                            onClick={() => {
                                navigate('/dynamic-table')
                            }}
                        >
                            <img src="/images/ArrowLeft.svg" width={24} height={24} alt="Go Back" />
                        </button>
                    </div>
                    <div className='flex items-center gap-2 p-2 ps-0'>
                        <label htmlFor="file-upload" className='hover:scale-105 duration-300 ease-in-out cursor-pointer bg-white text-[#2183F6] font-[500] px-[20px] py-[10px] rounded-[4px] mr-1 flex items-center gap-1'>Upload Document <img src="/images/FileArrowUp.svg" width={16} height={16} alt="UploadFile" /></label>
                        <input
                            id="file-upload"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            accept="application/pdf"
                            required
                        />
                        <button onClick={toggleShowAllHighlights} className='hover:scale-105 duration-300 ease-in-out cursor-pointer bg-white text-[#2183F6] font-[500] px-[20px] py-[10px] rounded-[4px] flex items-center gap-1' >
                            {showAllHighlights ? 'Hide Highlights' : 'Show Highlights'}
                            <img src="/images/Eye.svg" width={16} height={16} alt="EyeIcon" />
                        </button>
                        <button onClick={handleApiCall}
                            className='hover:scale-105 duration-300 ease-in-out cursor-pointer bg-white text-[#2183F6] font-[500] px-[20px] py-[10px] rounded-[4px] relative mx-1 flex items-center gap-1'>
                            {isApiLoading ? (
                                <i className="fas fa-spinner fa-spin"></i>
                            ) : (
                                <>
                                    <span>
                                        Autofill Word Doc
                                    </span>
                                    <img src="/images/PencilSimple.svg" width={16} height={16} alt="PencilIcon" />
                                </>
                            )}
                        </button>
                        <button onClick={handleDownloadPdf}
                            className='hover:scale-105 duration-300 ease-in-out cursor-pointer bg-white text-[#2183F6] font-[500] px-[20px] py-[10px] rounded-[4px] relative mx-1 flex items-center gap-1'>
                            Download PDF
                            <img src="/images/FileArrowDown.svg" width={16} height={16} alt="Download" />
                        </button>
                        <button className='w-[44px] h-[44px] bg-white flex items-center justify-center rounded-[4px]'>
                            <img src="/images/HouseSimple.svg" width={24} height={24} alt="Go Back" />
                        </button>
                    </div>
                </div>
                {pdfUrl && (
                    <div className='flex' style={{ position: 'relative', width: '100%', height: '95vh' }}>
                        <div className='min-w-[700px]'>
                            <Worker workerUrl={pdfjsWorker}>
                                <div style={{ height: '100%', width: '100%' }}>
                                    <Viewer
                                        fileUrl={pdfUrl}
                                        plugins={[zoomPluginInstance, highlightPluginInstance, pageNavigationPluginInstance]}
                                        onDocumentLoad={() => console.log('Document loaded')}
                                        defaultScale={.95}
                                    />
                                </div>
                            </Worker>
                        </div>
                        <div style={{ width: '370px' }}>
                            <div style={{ width: '100%', overflowY: 'auto', height: '110vh', padding: '0px 10px 0px 20px' }} className='Details'>
                                {jsonData ? (
                                    <>
                                        {Object.entries(jsonData).map(([key, value], index) => (
                                            <div
                                                key={index}
                                                onClick={() => handlePanelClick(value.boundingRegions)}
                                                style={{ padding: "10px", backgroundColor: 'white', color: 'black', cursor: 'pointer', position: 'relative', width: '100%', margin: '2px 0px', borderRadius: '4px' }}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <h4 style={{ textTransform: 'capitalize', margin: '0', color: '#535C69' }}>{key}</h4>
                                                    <p className='text-[#363C45] font-[500] text-[15px]'>{handlePercentages(value.confidence * 100)}%</p>
                                                </div>
                                                <p style={{ fontSize: '14px', fontWeight: 'bold', color: '#363C45' }}>{value.valueString}</p>
                                            </div>
                                        ))}
                                    </>
                                ) :
                                    <>
                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {loading ? <i className="fas fa-spinner fa-spin fa-3x"></i> : <h2>Please Upload Document</h2>}
                                        </div>
                                    </>}
                            </div>
                        </div>
                    </div>
                )}
            </div>

        </div>
    );
};

export default PDFViewertester;
