import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { GlobalContext } from '../context/GlobalContext';
import { useSocket } from '../context/SocketContext';

const FormsDynamicTable = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const { globalUserOrganizationAccessName } = useContext(GlobalContext);
    const navigate = useNavigate();
    const { socket, isConnected } = useSocket(); // Destructure socket and connection helper

    // Fetch data only once when the component mounts
    useEffect(() => {
        let isMounted = true; // Track component's mounted state to avoid setting state on unmounted components

        const fetchData = async () => {
            try {
                console.log('[FormsDynamicTable] WebSocket connected. Fetching data...');

                // Emit the get_items event
                socket.emit('get_items', {
                    fields: ['_id', 'loan_name', 'progress_step', 'last_updated'],
                });

                // Register event listeners only if the component is still mounted
                if (isMounted) {
                    socket.on('items', (items) => {
                        console.log('[FormsDynamicTable] Received items:', items);
                        const reversedItems = items.reverse();
                        setData(reversedItems);
                        setFilteredData(reversedItems);
                    });

                    socket.on('new_item', (item) => {
                        console.log('[FormsDynamicTable] New item received:', item);
                        setData((prevData) => [item, ...prevData]);
                        setFilteredData((prevData) => [item, ...prevData]);
                    });

                    socket.on('update_item', (updatedItem) => {
                        console.log('[FormsDynamicTable] Item updated:', updatedItem);
                        setData((prevData) =>
                            prevData.map((item) =>
                                item._id === updatedItem._id ? updatedItem : item
                            )
                        );
                        setFilteredData((prevData) =>
                            prevData.map((item) =>
                                item._id === updatedItem._id ? updatedItem : item
                            )
                        );
                    });

                    socket.on('delete_item', (id) => {
                        console.log(`[FormsDynamicTable] Item deleted with id: ${id}`);
                        setData((prevData) => prevData.filter((item) => item._id !== id));
                        setFilteredData((prevData) =>
                            prevData.filter((item) => item._id !== id)
                        );
                    });
                }
            } catch (error) {
                console.error('[FormsDynamicTable] Error during WebSocket connection:', error);
            }
        };

        if (isConnected) {
            fetchData(); // Initiate data fetching
        }

        // Cleanup listeners and avoid setting state if the component unmounts
        return () => {
            console.log('[FormsDynamicTable] Cleaning up listeners.');
            isMounted = false;
            socket?.off('items');
            socket?.off('new_item');
            socket?.off('update_item');
            socket?.off('delete_item');
        };
    }, [isConnected, socket]); // Empty dependency array to run once on component mount

    // Step mappings
    const steps = [
        { id: 1, title: 'Receive Bank Credit Memo via Email' },
        { id: 2, title: 'Upload Bank Credit Memo to Create NorCal Credit Memo' },
        { id: 3, title: 'Fix NorCal Credit Memo' },
        { id: 4, title: 'Upload New NorCal Credit Memo to Portal' },
        { id: 5, title: 'Generate IBank Documents' },
        { id: 6, title: 'Send Documents to Ibank' },
        { id: 7, title: 'Waiting for Ibank to Approve Loan' },
        { id: 8, title: 'IBank Documents Approved' },
        { id: 9, title: 'Send 6 Questions Email' },
        { id: 10, title: 'Waiting for 6 Questions' },
        { id: 11, title: '6 Questions Email Answered' },
        { id: 12, title: 'Update Forms with 6 Questions' },
        { id: 13, title: 'Generate New Forms and Send to Bank' },
        // { id: 14, title: 'Waiting for Signature from Bank' },
        // { id: 15, title: 'Bank has Signed Forms and Dispersed Payment' },
        // { id: 16, title: 'Notify Ms.Nikki that Loan is Completed' },
        // { id: 17, title: 'Generate NorCal Invoice to Disperse the Payment' },
        // { id: 18, title: 'Loan Complete' },
    ];
    const customStyles = {
        headRow: {
            style: {
                borderRadius: '4px', // Add border radius to the header bar
                backgroundColor: '#e2e4e7', // Optional: set a background color for the header
                // padding: '10px', // Optional: add some padding to the header cells
            },
        },
        headCells: {
            style: {
                fontWeight: 'bold', // Optional: make the header text bold
            },
        },
        
        rows: {
            style: {
                paddingTop: '24px',    // Top padding
                paddingBottom: '24px', // Bottom padding
                borderRadius: '4px'
            },
        },
        // headCells: {
        //     style: {
        //         backgroundColor: '#e2e4e7',

        //     },
        // },
    };


    // Table columns definition
    const columns = [
        {
            name: <span className='text-[#363C45] text-[12px]'>Loan Name</span>,
            selector: row => row.loan_name || 'No Loan Name',
            sortable: true,
            cell: row => (
                <span
                    className="text-[22px] text-[#363C45] font-[500] leading-[24px] cursor-pointer"
                    onClick={() => handleRowClick(row)} // Row click now triggers only on the Loan Name
                >
                    {row.loan_name || 'No Loan Name'}
                </span>
            ),
            width:'17.26%',
        },
        {
            name: 'Current Step Status',
            selector: row => {
                const currentStep = steps.find(step => step.id === row.progress_step);
                return currentStep ? currentStep.title : 'Unknown Status';
            },
            sortable: true,
            cell: row => {
                const currentStep = steps.find(step => step.id === row.progress_step);
                return (
                    <div>
                        <span className="className='text-[#363C45] font-[500] text-[15px]'">{currentStep ? currentStep.title : 'Unknown Status'}</span>
                        <ProgressBar currentStep={row.progress_step} />
                    </div>
                );
            },
            width: '34.63%',
        },
        {
            name: 'Next Step',
            selector: row => {
                const nextStep = steps.find(step => step.id === row.progress_step + 1);
                return nextStep ? nextStep.title : 'Final Step';
            },
            sortable: true,
            cell: row => {
                const nextStep = steps.find(step => step.id === row.progress_step + 1);
                return <span className="className='text-[#363C45] font-[500] text-[15px]'">{nextStep ? nextStep.title : 'Final Step'}</span>;
            },
            width:'20.13%'
        },
        {
            name: 'Last Activity',
            selector: row => moment(row.last_updated).fromNow(),
            sortable: true,
            cell: row => <span className="text-[14px] text-[#535C69] font-[400] leading-[24px]">{moment(row.last_updated).fromNow()}</span>,
            width:'8.63%',
        },
        {
            name: 'Created',
            selector: row => moment(row.last_updated).format('MMM D, YYYY'),
            sortable: true,
            cell: row => <span className="text-[14px] text-[#535C69] font-[400] leading-[24px]">{moment(row.last_updated).format('MMM D, YYYY')}</span>,
             width:'7.24%'
        },
        {
            name: 'Actions',
            cell: row => (
                <div className="ms-2">
                    <button
                        className="text-red-600 hover:text-red-800 transition flex items-center justify-center"
                        onClick={(event) => {
                            event.stopPropagation(); // Prevent navigation on delete click
                            handleDelete(row._id); // Call the delete function
                        }}
                    >
                        <img src="/images/Trash.svg" width={16} height={16} alt="Delete" />
                    </button>
                </div>
            ),
            width:'8.63%'
        },
    ];

    const handleRowClick = (row) => {
        console.log('[FormsDynamicTable] Row clicked:', row);
        fetchAdditionalDataAndNavigate(row._id);
    };

    const fetchAdditionalDataAndNavigate = (id) => {
        const fieldsToFetch = ['pdfBase64', 'docAiResults', 'googleDocsIframe', '_id', 'loan_name', 'progress_step', 'pdfBase64NorCalTemplate', 'docAiResultsNorCalTemplate'];

        console.log('[FormsDynamicTable] Fetching additional data for id:', id);
        console.log('[FormsDynamicTable] Fields to fetch:', fieldsToFetch);
        socket.emit('get_items', { id, fields: fieldsToFetch });

        socket.on('item', (data) => {
            console.log('[FormsDynamicTable] Received item data:', data);
            if (data.length > 0) {
                const item = data[0];
                console.log('[FormsDynamicTable] Navigating to individual-loan-tracker with item:', item);
                navigate('/individual-loan-tracker', {
                    state: {
                        pdfBase64NorCalTemplate: item.pdfBase64NorCalTemplate,
                        docAiResultsNorCalTemplate: item.docAiResultsNorCalTemplate,
                        pdfBase64: item.pdfBase64,
                        jsonData: item.docAiResults,
                        iframe: item.googleDocsIframe,
                        formId: item._id,
                        progressStep: item.progress_step,
                        loanName: item.loan_name
                    },
                });
            } else {
                console.log('[FormsDynamicTable] No item data received');
            }
        });
    };

    const addItem = () => {
        const loan_name = prompt("Enter loan name:");
        if (loan_name) {
            console.log(`[FormsDynamicTable] Adding new loan: ${loan_name}`);
            console.log('[FormsDynamicTable] Emitting add_item event with loan_name:', loan_name, 'and user_organization_access_name:', globalUserOrganizationAccessName);
            socket.emit('add_item', { loan_name: loan_name, user_organization_access_name: globalUserOrganizationAccessName });
        } else {
            console.log('[FormsDynamicTable] Add item cancelled: No loan name provided');
        }
    };

    const handleDelete = (id) => {
        console.log(`[FormsDynamicTable] Deleting loan with id: ${id}`);
        console.log('[FormsDynamicTable] Emitting delete_item event with id:', id);
        socket.current.emit('delete_item', id);
    };

    const searchLoans = (e) => {
        const input = e.target.value.toLowerCase();
        console.log('[FormsDynamicTable] Searching loans with input:', input);
        if (input) {
            const filtered = data.filter(item => item.loan_name?.toLowerCase().includes(input));
            console.log('[FormsDynamicTable] Filtered data:', filtered);
            setFilteredData(filtered);
        } else {
            console.log('[FormsDynamicTable] Resetting filtered data to full data');
            setFilteredData(data);
        }
    };

    return (
        <div className="p-6 bg-gray-100 min-h-screen pt-[80px]">
            <div >
                <div className="flex justify-between items-center p-4">
                    <div className='flex gap-2 items-center'>
                        <h2 className="text-[36px] font-[500] text-[#363C45]">Loan Directory</h2> {/* Display number of results */}
                        <span className='text-[#535C69] text-[12px] font-[500] mt-4'>{filteredData.length} Loans</span>
                    </div>
                    <div className="flex space-x-4">
                        <div className='relative'>
                            <input
                                type="text"
                                id="searchBar"
                                className="border border-gray-300 rounded-md p-2 text-sm ps-6"
                                placeholder="Search loans"
                                onChange={searchLoans}
                            />
                            <img src="/images/MagnifyingGlass.svg" width={16} height={16} className='absolute top-[12px] left-2' alt="" />
                        </div>
                        <button
                            onClick={addItem}
                            className="bg-[#2183F6] text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
                        >
                            Add New Loan +
                        </button>
                    </div>
                </div>
                <div className='flex items-center gap-2 p-3'>
                    <button className='text-[#363C45] flex gap-2 text-[11px] items-center rounded-full bg-[#C2C7CF] px-[8px] py-[6px]'>
                        <span className='w-[24px] h-[24px] bg-white rounded-full'><img src="/images/tick-circle.svg" width={24} height={24} alt="" /></span> Show All
                    </button>
                    <button className='text-white flex gap-2 text-[11px] items-center rounded-full bg-[#2E90FA] px-[8px] py-[6px]'>
                        <span className='w-[24px] h-[24px] bg-white rounded-full'></span> Requires Action
                    </button>
                    <button className='text-white flex gap-2 text-[11px] items-center rounded-full bg-[#039855] px-[8px] py-[6px]'>
                        <span className='w-[24px] h-[24px] bg-white rounded-full'></span> Completed
                    </button>
                    <button className='text-white flex gap-2 text-[11px] items-center rounded-full bg-[#EAB816] px-[8px] py-[6px]'>
                        <span className='w-[24px] h-[24px] bg-white rounded-full'></span> Pending
                    </button>
                    <button className='text-white flex gap-2 text-[11px] items-center rounded-full bg-[#E75B51] px-[8px] py-[6px]'>
                        <span className='w-[24px] h-[24px] bg-white rounded-full'></span> Rejected
                    </button>
                    <div className='px-[12px] border-l-2'>
                        <button className='text-[#535C69] flex gap-2 text-[11px] items-center rounded-full bg-[#C2C7CF] px-[8px] py-[6px]'>
                            <span className='w-[24px] h-[24px] bg-white rounded-full'></span> Archived
                        </button>
                    </div>
                </div>

                <div>
                    <p className='text-[12px] text-[#535c69] px-4 mb-3'>Displaying {filteredData.length} results</p>
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        defaultSortField="loan_name"
                        className="table-auto w-[100vw] bg-transparent"
                        onRowClicked={handleRowClick}
                        highlightOnHover
                        pointerOnHover
                        customStyles={customStyles}
                    />

                </div>
            </div>
        </div>
    );
};

const ProgressBar = ({ currentStep }) => {
    const totalSteps = 13;
    const filledBlocks = currentStep;
    const unfilledBlocks = totalSteps - filledBlocks;

    return (
        <div className="flex items-center my-2 flex-wrap gap-y-[0.5rem]">
            {/* Filled Blocks (Green) */}
            {Array(filledBlocks - 1)
                .fill(0)
                .map((_, index) => (
                    <div
                        key={`filled-${index}`}
                        className="w-[36px] h-[8px] bg-[#0BBC78] mr-1 rounded-full"
                    />
                ))}
            {/* Last Filled Block (Blue) */}
            {filledBlocks > 0 && (
                <div
                    key={`filled-last`}
                    className="w-[36px] h-[8px] bg-[#0B51BC] mr-1 rounded-full"
                />
            )}
            {/* Unfilled Blocks (Gray) */}
            {Array(unfilledBlocks)
                .fill(0)
                .map((_, index) => (
                    <div
                        key={`unfilled-${index}`}
                        className="w-[36px] h-[8px] bg-gray-300 mr-1 rounded-full"
                    />
                ))}
        </div>
    );
};

export default FormsDynamicTable;
