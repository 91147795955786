import React, { useReducer, useEffect, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useSocket } from '../context/SocketContext';

const Container = styled.div`
  padding: 0px 20px;
`;

const PDFContainerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PDFContainerRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const PDFContainer = styled.div`
  width: 48%;
  height: 620px;
  border: 2px solid #ccc;
  padding: 10px;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  aspect-ratio: 8.5 / 11;
`;

const Popup = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #dff0d8;
  color: #3c763d;
  padding: 15px;
  border-radius: 5px;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
`;

const IndividualLoanTracker = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { socket, isConnected } = useSocket();

  const {
    pdfBase64: initialPdfUrl,
    jsonData: initialJsonData,
    iframe: initialIframeData,
    formId: initialFormId,
    progressStep: initialProgressStep,
    pdfBase64NorCalTemplate,
    docAiResultsNorCalTemplate,
    loanName: loan_name,
  } = location.state || {};

  const initialState = {
    initialData: {
      pdfBase64: initialPdfUrl,
      jsonData: initialJsonData,
      iframe: initialIframeData,
      formId: initialFormId,
      progressStep: initialProgressStep || 2,
      pdfBase64NorCalTemplate,
      docAiResultsNorCalTemplate,
      loan_name,
    },
    steps: [
      { id: 0, key: '0', title: 'None', completed: true },
      { id: 1, key: '1', title: 'Receive Bank Credit Memo via Email.', completed: true },
      { id: 2, key: '2', title: 'Upload Bank Credit Memo to Create NorCal Credit Memo', completed: false, action: 'pdf-viewer' },
      { id: 3, key: '3', title: 'Fix NorCal Credit Memo', completed: false, action: 'pdf-viewer' },
      { id: 4, key: '4', title: 'Upload New NorCal Credit Memo to Portal', completed: false, action: 'custom-pdf-viewer-tables' },
      { id: 5, key: '5', title: 'Generate IBank Documents', completed: false, action: 'custom-pdf-viewer-tables' },
      { id: 6, key: '6', title: 'Send Documents to IBank', completed: false, action: 'custom-pdf-viewer-tables' },
      { id: 7, key: '7_wait', title: 'Waiting for IBank to Approve Loan', completed: false, wait: true },
      { id: 8, key: '8', title: 'IBank Documents Approved', completed: false, status: true },
      { id: 9, key: '9', title: 'Send 6 Questions Email', completed: false },
      { id: 10, key: '10_wait', title: 'Waiting for 6 Questions', completed: false, wait: true },
      { id: 11, key: '11', title: '6 Questions Email Answered', completed: false, status: true },
      { id: 12, key: '12', title: 'Update Forms with 6 Questions', completed: false, action: 'custom-pdf-viewer-tables' },
      { id: 13, key: '13', title: 'Generate New Forms and Send to Bank', completed: false, action: 'custom-pdf-viewer-tables' },
      { id: 14, key: '14', title: 'None', completed: false },
    ],
    showPopup: false,
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'SET_INITIAL_DATA':
        return {
          ...state,
          initialData: {
            ...state.initialData,
            ...action.payload,
          },
        };
      case 'SET_STEPS':
        return {
          ...state,
          steps: action.payload,
        };
      case 'SET_SHOW_POPUP':
        return {
          ...state,
          showPopup: action.payload,
        };
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const [currentStep, setCurrentStep] = useState(state.initialData.progressStep || 2);

  useEffect(() => {
    setCurrentStep(state.initialData.progressStep);
  }, [state.initialData.progressStep]);

  const [dataFetched, setDataFetched] = useState(false);

  const fetchAdditionalData = useCallback(
    (id) => {
      if (socket) {
        const fieldsToFetch = [
          'pdfBase64',
          'docAiResults',
          'googleDocsIframe',
          '_id',
          'loan_name',
          'progress_step',
          'pdfBase64NorCalTemplate',
          'docAiResultsNorCalTemplate',
        ];
        socket.emit('get_items', { id, fields: fieldsToFetch });

        const handleItemData = (data) => {
          if (data && data.length > 0) {
            const fetchedData = data[0];
            dispatch({
              type: 'SET_INITIAL_DATA',
              payload: {
                pdfBase64: fetchedData.pdfBase64,
                jsonData: fetchedData.docAiResults,
                iframe: fetchedData.googleDocsIframe,
                formId: fetchedData._id,
                progressStep: fetchedData.progress_step,
                pdfBase64NorCalTemplate: fetchedData.pdfBase64NorCalTemplate,
                docAiResultsNorCalTemplate: fetchedData.docAiResultsNorCalTemplate,
                loan_name: fetchedData.loan_name,
              },
            });
            setDataFetched(true);
          }
        };

        socket.on('item', handleItemData);

        return () => {
          socket.off('item', handleItemData);
        };
      } else {
        console.error('[IndividualLoanTracker] Socket is not available');
      }
    },
    [socket]
  );

  useEffect(() => {
    if (!dataFetched && state.initialData.formId) {
      fetchAdditionalData(state.initialData.formId);
    }
  }, [state.initialData.formId, fetchAdditionalData, dataFetched]);

  useEffect(() => {
    dispatch({
      type: 'SET_STEPS',
      payload: state.steps.map((step, index) => ({
        ...step,
        completed: index < currentStep,
      })),
    });
  }, [currentStep]);

  const pdfUrl1 = useMemo(() => {
    if (state.initialData.pdfBase64) {
      try {
        const byteCharacters = atob(state.initialData.pdfBase64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        return URL.createObjectURL(blob);
      } catch (error) {
        console.error('[IndividualLoanTracker] Error converting base64 to Blob:', error);
        return null;
      }
    }
    return null;
  }, [state.initialData.pdfBase64]);

  const pdfUrl2 = useMemo(() => {
    if (state.initialData.pdfBase64NorCalTemplate) {
      try {
        const byteCharacters = atob(state.initialData.pdfBase64NorCalTemplate);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        return URL.createObjectURL(blob);
      } catch (error) {
        console.error('[IndividualLoanTracker] Error converting base64 to Blob:', error);
        return null;
      }
    }
    return null;
  }, [state.initialData.pdfBase64NorCalTemplate]);

  const pdfUrl3 = null;

  useEffect(() => {
    return () => {
      if (pdfUrl1) {
        URL.revokeObjectURL(pdfUrl1);
      }
      if (pdfUrl2) {
        URL.revokeObjectURL(pdfUrl2);
      }
      if (pdfUrl3) {
        URL.revokeObjectURL(pdfUrl3);
      }
    };
  }, [pdfUrl1, pdfUrl2, pdfUrl3]);

  useEffect(() => {
    if (!socket || !isConnected) {
      console.error('[IndividualLoanTracker] Socket is not available');
      return;
    }

    const handleEmailSent = (response) => {
      console.log('[IndividualLoanTracker] Email sent:', response.msg);
      dispatch({ type: 'SET_SHOW_POPUP', payload: true });
      setTimeout(() => dispatch({ type: 'SET_SHOW_POPUP', payload: false }), 3000);
    };

    socket.on('email_sent', handleEmailSent);

    return () => {
      socket.off('email_sent', handleEmailSent);
    };
  }, [socket, isConnected]);

  const customFetch = useCallback((url, options = {}) => {
    return fetch(url, {
      ...options,
      credentials: 'include',
    });
  }, []);

  const toggleComplete = (id) => {
    dispatch({
      type: 'SET_STEPS',
      payload: state.steps.map((step) => ({
        ...step,
        completed: step.id <= id,
      })),
    });

    dispatch({
      type: 'SET_INITIAL_DATA',
      payload: { progressStep: id },
    });

    setCurrentStep(id);

    const updatedItem = {
      _id: state.initialData.formId,
      progress_step: id,
    };

    if (socket) {
      socket.emit('update_items', { items: [updatedItem] }, (response) => {
        console.log('[IndividualLoanTracker] Update Items Response:', response);
      });
    } else {
      console.error('[IndividualLoanTracker] Socket connection not established.');
    }
  };

  const handleAutomationClick = (stepId) => {
    if (state.initialData.formId) {
      let apiUrl = '';
      switch (stepId) {
        case 5:
          apiUrl = 'https://api.formcopilot.app/create_nor_cal_form_web_app';
          break;
        case 9:
          apiUrl = 'https://api.formcopilot.app/action_extension_send_6_questions_email';
          break;
        case 12:
          apiUrl = 'https://api.formcopilot.app/update_6_questions_web_app';
          break;
        default:
          break;
      }

      if (apiUrl) {
        customFetch(apiUrl, {
          method: 'POST',
          body: JSON.stringify({ formId: state.initialData.formId }),
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(`[IndividualLoanTracker] API call to ${apiUrl} succeeded:`, data);
          })
          .catch((error) => {
            console.error(`[IndividualLoanTracker] Error calling ${apiUrl}:`, error);
          });
      }
    } else {
      console.error('[IndividualLoanTracker] Form ID is missing.');
    }
  };

  const renderSwiperSteps = (steps, currentStep) => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(currentStep - 1);

    const handleSlideChange = (swiper) => {
      const newIndex = swiper.activeIndex;
      setActiveSlideIndex(newIndex);
      toggleComplete(newIndex + 1);
    };

    return (
      <Swiper
        spaceBetween={10}
        slidesPerView={3}
        modules={[Navigation, Autoplay]}
        navigation={{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }}
        className="mySwiper"
        style={{ height: '70px' }}
        initialSlide={currentStep - 1}
        onSlideChange={handleSlideChange}
      >
        {steps.map((step, index) => {
          let label = '';
          if (index < currentStep) {
            label = 'Previous Step';
          } else if (index === currentStep) {
            label = 'Current Step';
          } else {
            label = 'Next Step';
          }

          return (
            <SwiperSlide key={step.id}>
              <div
                style={{
                  color: activeSlideIndex + 1 === index ? 'black' : '#A9A9A9',
                  padding: '15px',
                  height: '70px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '8px',
                  textAlign: 'center',
                }}
              >
                <span
                  className="text-start text-[#535C69]"
                  style={{ fontSize: '12px', fontWeight: 'bold', marginBottom: '5px' }}
                >
                  {label}
                </span>
                <div className="flex items-center justify-between gap-1">
                  <h3
                    style={{
                      fontSize: activeSlideIndex + 1 === index ? '18px' : '15px',
                      textAlign: activeSlideIndex + 1 === index ? 'center' : 'start',
                    }}
                    className="font-[600] leading-[20px]"
                  >
                    {step.title}
                  </h3>
                  {index === activeSlideIndex + 1 && (
                    <button
                      onClick={() => handleAutomationClick(step.id)}
                      className="px-[12px] py-[8px] bg-[#2183F6] flex items-center gap-1 rounded-sm text-white font-[500] text-[12px]"
                    >
                      Automation <img src="/images/MagicStick.svg" width={16} height={16} alt="MagicStick" />
                    </button>
                  )}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
        <div className="swiper-button-next" style={{ top: '70%' }}></div>
        <div className="swiper-button-prev" style={{ top: '70%' }}></div>
      </Swiper>
    );
  };

  const navigateToPage = (page) => {
    if (page) {
      switch (page) {
        case 'pdf-viewer':
          navigate('/pdf-viewer', {
            state: {
              pdfBase64: state.initialData.pdfBase64,
              jsonData: state.initialData.jsonData,
              iframe: state.initialData.iframe,
              formId: state.initialData.formId,
            },
          });
          break;
        case 'custom-pdf-viewer-tables':
          navigate('/custom-pdf-viewer-tables', {
            state: {
              pdfBase64: state.initialData.pdfBase64NorCalTemplate,
              jsonData: state.initialData.docAiResultsNorCalTemplate,
              formId: state.initialData.formId,
            },
          });
          break;
        default:
          console.warn('Unknown action:', page);
          break;
      }
    }
  };

  return (
    <div className="bg-[#f4f5f5]">
      <div className="py-1 px-5">
        <div className="flex justify-between items-center">
          <div>
            <button onClick={() => navigate('/dynamic-table')} className="flex items-center gap-1 text-[#2183F6]">
              <img src="/images/ArrowLeft.svg" width={16} height={16} alt="Back to Home" /> Back to home
            </button>
          </div>
          <div className="flex gap-2">
            <button className="font-[500] px-[16px] py-[6px] rounded-md text-[#2183F6] bg-white">
              Secondary Action +
            </button>
            <button className="font-[500] px-[16px] py-[6px] rounded-md text-[#2183F6] bg-white">
              Secondary Action +
            </button>
            <button className="font-[500] px-[16px] py-[6px] rounded-md text-[#2183F6] bg-white">
              Secondary Action +
            </button>
            <button
              className="w-[44px] h-[44px] bg-white flex items-center justify-center rounded-[4px]"
              onClick={() => {
                navigate('/dynamic-table');
              }}
            >
              <img src="/images/HouseSimple.svg" width={24} height={24} alt="Go Back" />
            </button>
          </div>
        </div>
        <div>
          <h3 className="text-[28px] text-[#363C45] font-[500] mb-2">{state.initialData.loan_name}</h3>
          <div className="grid grid-cols-3">
            <div className="ms-1">
              <h2 className="text-[15px] font-[500] leading-[20px] text-black">Bank Credit Memo</h2>
              <p className="text-[#535C69] text-[14px] leading-[24px]">
                Upload Bank’s Credit Memo to Create NorCal Credit Memo
              </p>
            </div>
            <div className="ms-2">
              <h2 className="text-[15px] font-[500] leading-[20px] text-black">NorCal Credit Memo</h2>
              <p className="text-[#535C69] text-[14px] leading-[24px]">
                Upload NorCal Credit Memo to Generate Documents
              </p>
            </div>
            <div className="ms-4">
              <h2 className="text-[15px] font-[500] leading-[20px] text-black">None</h2>
              <p className="text-[#535C69] text-[14px] leading-[24px]">None</p>
            </div>
          </div>
        </div>
      </div>
      <Container>
        <PDFContainerWrapper>
          <PDFContainerRow className="gap-5">
            <Worker workerUrl={pdfjsWorker}>
              <PDFContainer onClick={() => navigateToPage('pdf-viewer')}>
                {pdfUrl1 ? (
                  <div style={{ height: '100%', width: '100%' }}>
                    <Viewer
                      fileUrl={pdfUrl1}
                      onDocumentLoad={() => console.log('Document loaded')}
                      defaultScale={SpecialZoomLevel.PageWidth}
                    />
                  </div>
                ) : (
                  <div className="flex flex-col items-center">
                    <img src="/images/FileArrowUp.svg" width={48} height={48} alt="FileArrowUp" />
                    <p className="text-[#2183F6] font-[500]">Upload document "name"</p>
                  </div>
                )}
              </PDFContainer>
              <PDFContainer onClick={() => navigateToPage('custom-pdf-viewer-tables')}>
                {pdfUrl2 ? (
                  <div style={{ height: '100%', width: '100%' }}>
                    <Viewer
                      fileUrl={pdfUrl2}
                      onDocumentLoad={() => console.log('Document loaded')}
                      defaultScale={SpecialZoomLevel.PageWidth}
                    />
                  </div>
                ) : (
                  <div className="flex flex-col items-center">
                    <img src="/images/FileArrowUp.svg" width={48} height={48} alt="FileArrowUp" />
                    <p className="text-[#2183F6] font-[500]">Upload document "name"</p>
                  </div>
                )}
              </PDFContainer>
              <PDFContainer>
                {pdfUrl3 ? (
                  <div style={{ height: '100%', width: '100%' }}>
                    <Viewer
                      fileUrl={pdfUrl3}
                      onDocumentLoad={() => console.log('Document loaded')}
                      defaultScale={SpecialZoomLevel.PageWidth}
                    />
                  </div>
                ) : (
                  <div className="flex flex-col items-center">
                    <img src="/images/FileArrowUp.svg" width={48} height={48} alt="FileArrowUp" />
                    <p className="text-[#2183F6] font-[500]">Upload document "name"</p>
                  </div>
                )}
              </PDFContainer>
            </Worker>
          </PDFContainerRow>
        </PDFContainerWrapper>

        {state.showPopup && <Popup>6 Questions Email Sent</Popup>}
      </Container>
      <div className="bg-white py-1 px-2">
        <div>{renderSwiperSteps(state.steps, currentStep)}</div>
        <ProgressBar currentStep={currentStep} />
      </div>
    </div>
  );
};

export default IndividualLoanTracker;

const ProgressBar = ({ currentStep }) => {
  const totalSteps = 13;
  const filledBlocks = currentStep;
  const unfilledBlocks = totalSteps - filledBlocks;

  return (
    <div className="flex items-center my-2 space-x-1 px-[80px] mt-3">
      {Array(filledBlocks - 1)
        .fill(0)
        .map((_, index) => (
          <div key={`filled-${index}`} className="w-[177px] h-[24px] bg-[#0BBC78] rounded-full" />
        ))}
      {filledBlocks > 0 && <div key={`filled-last`} className="w-[177px] h-[24px] bg-[#0B51BC] rounded-full" />}
      {Array(unfilledBlocks)
        .fill(0)
        .map((_, index) => (
          <div key={`unfilled-${index}`} className="w-[177px] h-[24px] bg-gray-300 rounded-full" />
        ))}
    </div>
  );
};
