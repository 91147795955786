// src/layouts/ProtectedLayout.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { SocketProvider } from '../context/SocketContext'; // Import SocketProvider

const ProtectedLayout = ({ isAuthenticated, accessLevel }) => {
    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    return (
        <SocketProvider>
            <Outlet /> {/* Render child routes inside this layout */}
        </SocketProvider>
    );
};

export default ProtectedLayout;
